import {getRecord, getRecords, postRecord, putRecord, removeRecord} from "@/services/api";

export function getClients(filterQuery, options = {}) {
    filterQuery.datatable = true
    if (options.toggleProgressSpinner !== true) options.toggleProgressSpinner = false
    return getRecords('/prologistics/dashboard/clients.json', filterQuery, options)
}

export function getClient(id, filterQuery = {}, options = {}) {
    return getRecord('/prologistics/dashboard/clients/' + id + '.json', filterQuery, options)
}

export function removeClient(id, options = {}) {
    return removeRecord('/prologistics/dashboard/clients/', id, options)
}

export function postClient(data, options = {}) {
    return postRecord('/prologistics/dashboard/clients.json', data, options)
}

export function putClient(id, data, options = {}) {
    return putRecord('/prologistics/dashboard/clients/' + id + '.json', data, options)
}

export function getSelectableClients(filterQuery, options = {}) {
    filterQuery.selectable = true
    return getRecords('/prologistics/dashboard/clients.json', filterQuery, options)
}

export function getSelectableRoles(filterQuery, options = {}) {
    filterQuery.selectable = true
    return getRecords('/prologistics/dashboard/clients/roles.json', filterQuery, options)
}